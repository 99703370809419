import Head from 'next/head';
import Link from 'next/link';
import React from 'react';

export default function FourOhFour() {
  return (
    <div
      style={{
        height: 500,
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Head>
        <meta name="robots" content="noindex" />
      </Head>
      <h2>404 - Page Not Found</h2>
      <Link href="/">Go back home</Link>
    </div>
  );
}
